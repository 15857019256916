import { PlayStopNewConsultationAudio } from "@astrocentro-webapp/sellers/modules/commons/audios/PlayStopNewConsultationAudio.js";
import { watch } from "vue";
import { useDocumentVisibility } from "@vueuse/core";
import { hasActiveServiceWorker } from '@astrocentro-webapp/sellers/services/serviceWorkerRegistration';

export const playNewConsultationAudioListener = () => {
  const newConsultationAudio = new PlayStopNewConsultationAudio();

  // TODO: alerta quando receber solicitação de consulta e aba não estiver ativa: https://canoadigital.atlassian.net/jira/software/projects/AST/boards/91?selectedIssue=AST-992
  const visibility = useDocumentVisibility();

  let toggleTitle = null;

  if (visibility.value !== "visible") {
    toggleTitle = setInterval(function () {
      toggleTabTitle();
    }, 2000);

    if (hasActiveServiceWorker()) {
      navigator.serviceWorker.controller.postMessage({ type: 'consultation-request' });
    }
  }

  watch(visibility, (current, previous) => {
    if (current === "visible" && previous === "hidden") {
      clearInterval(toggleTitle);
      const favicon = document.getElementById("favicon");
      favicon && (favicon.href = "/favicon.ico");
      document.title = "Chat Astrocentro";
    }
  });

  // Ask for notification permission to ensure service worker can show notifications
  if ('Notification' in window && Notification.permission === 'default') {
    Notification.requestPermission();
  }
  
  newConsultationAudio.play();
};

const toggleTabTitle = () => {
  const favicon = document.getElementById("favicon");
  favicon && (favicon.href = "/alert.ico");
  if (document.title == "Chat Astrocentro") {
    document.title = "Solicitação de consulta";
  } else {
    document.title = "Chat Astrocentro";
  }
};
