<script setup>
import { ref, onMounted, computed, provide } from "vue";
import { useRoute, onBeforeRouteUpdate, onBeforeRouteLeave } from "vue-router";
import { useObservable } from "@vueuse/rxjs";
import { liveQuery } from "dexie";

import { TheHeader } from "@astrocentro-webapp/sellers/modules/commons/components/TheHeader";
import { Notes } from "@astrocentro-webapp/sellers/modules/commons/components/Notes";
import { ListConversations } from "@astrocentro-webapp/sellers/modules/chat/components/ListConversations";
import { ChatFinishingConsultationModal } from "@astrocentro-webapp/sellers/modules/chat/components/ChatFinishingConsultationModal";
import { ChatConversation } from "@astrocentro-webapp/ui-components/modules/chat/components/ChatConversation";
import { ChatConnectionStatus } from "@astrocentro-webapp/ui-components/modules/chat/components/ChatConnectionStatus";
import { LoginService } from "@astrocentro-webapp/sellers/modules/login/services/LoginService";
import { useConsultationsStore } from "@astrocentro-webapp/sellers/stores/consultations";
import { RetryMessageSendingService } from "@astrocentro-webapp/sellers/modules/chat/services/RetryMessageSendingService";
import { useActiveConsultationStore } from "@astrocentro-webapp/sellers/stores/activeConsultation";
import { SellerPreConsultation } from "@astrocentro-webapp/sellers/modules/chat/components/SellerPreConsultation";
import { PlayStopNewConsultationAudio } from "@astrocentro-webapp/sellers/modules/commons/audios/PlayStopNewConsultationAudio.js";
import { ConversationRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/ConversationRepository";
import { ChatEvents } from "@astrocentro-webapp/sellers/modules/chat/events/ChatEvents";
import { DateHelper } from "@astrocentro-webapp/sellers/modules/commons/helpers/DateHelper";
import { ConsultationChannelEvents } from "@astrocentro-webapp/sellers/modules/chat/events/ConsultationChannelEvents";
import { useBreakpointSmallerThanMd } from "@astrocentro-webapp/ui-components/modules/commons/composables/browser/useBreakpointSmallerThanMd";

const isLoading = ref(true);
let consultation = ref({});
const isFinishConsultationModalOpen = ref(false);
const isChatNoteOpen = ref(false);
const consultationStatus = ref("");
const conversations = ref([]);
const chatConsultationChannelEvents = ref(null);
const { isSmallerThanMd } = useBreakpointSmallerThanMd();
provide("chat-events", new ChatEvents());
provide("chat-consultation-channel-events", chatConsultationChannelEvents);
provide("chat-retry-message-sending-service", new RetryMessageSendingService());
const loginService = new LoginService();
const conversationRepository = new ConversationRepository();
const dateHelper = new DateHelper();
const currentDate = dateHelper.format(null, "yyyy-MM-dd");

const getConsultationObserver = () => {
  return liveQuery(async () => {
    const storedConversation = await conversationRepository.getFirst({
      date: currentDate,
      chatRoomId: consultation.value.chat_room_id,
    });
    conversations.value = [storedConversation];

    return [];
  });
};

const conversationsObserver = computed(() =>
  useObservable(getConsultationObserver())
);

const user = await loginService.getLoggedInUser();
const recipientUserType = user.type == "seller" ? "customer" : "seller";
const route = useRoute();
setTimeout(() => (isLoading.value = false), 3000);
const consultationsStore = useConsultationsStore();
const activeConsultationStore = useActiveConsultationStore();
const newConsultationAudio = new PlayStopNewConsultationAudio();

consultationsStore.$subscribe((mutation, state) => {
  if (state.consultations["Chamadas Ativas"]) {
    setConsultation(route.params.consultationId);
  }
});

const setConsultation = async (consultationId) => {
  // TODO: Refactor how current seller consultations are stored in global state.

  let [consultationItem] = await consultationsStore.consultations[
    "Chamadas Ativas"
  ].filter((item) => item.id == consultationId);
  consultationItem && (consultation.value = consultationItem);
  chatConsultationChannelEvents.value = new ConsultationChannelEvents(
    consultation.value.id
  );

  let [inactiveConsultationItem] = await consultationsStore.consultations[
    "Chamadas Inativas"
  ].filter((item) => item.id == consultationId);
  inactiveConsultationItem && (consultation.value = inactiveConsultationItem);

  consultationStatus.value = consultation.value.last_status;
};

onBeforeRouteLeave(() => {
  activeConsultationStore.$patch(async (state) => {
    if (state.activeConsultation.id) {
      state.activeConsultation = {};
    }
  });
});

onBeforeRouteUpdate(async (to) => {
  setConsultation(to.params.consultationId);
  newConsultationAudio.stop();
});

onMounted(() => {
  newConsultationAudio.stop();
});
</script>

<template>
  <main class="d-flex flex-column">
    <ChatConnectionStatus
      v-if="isSmallerThanMd && consultation?.last_status !== 'finished'"
      :channelName="chatConsultationChannelEvents?.channelName"
      memberType="seller"
    />
    <TheHeader />
    <div class="container-fluid h-100">
      <div class="row h-100 position-relative">
        <div
          class="col-lg-4 px-0 d-none d-lg-block h-100 border-end border-gray-100 list-conversation"
        >
          <ListConversations />
        </div>
        <div class="col-lg-8 px-0 show-conversation">
          <div class="px-3 h-100" v-if="consultationStatus == 'requested'">
            <div class="row justify-content-center align-items-center h-100">
              <div class="col-sm-8">
                <SellerPreConsultation
                  :consultation="consultation"
                  @open-chat-note="isChatNoteOpen = true"
                />
              </div>
            </div>
          </div>

          <ChatConversation
            :consultation="consultation"
            :user="user"
            :isLoading="isLoading"
            :conversations="conversations"
            :keepRendering="conversationsObserver.value"
            @intends-finish-consultation="isFinishConsultationModalOpen = true"
            @open-chat-note="isChatNoteOpen = true"
            v-if="consultationStatus != 'requested'"
          />
          <Notes
            :customer="consultation?.customer"
            :noteOpen="isChatNoteOpen"
            @close-chat-note="isChatNoteOpen = false"
          />
        </div>
      </div>
    </div>
    <ChatFinishingConsultationModal
      :open="isFinishConsultationModalOpen"
      :consultation="consultation"
      @modal-closed="isFinishConsultationModalOpen = false"
    />
  </main>
</template>
<style lang="scss" scoped>
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";
.list-conversation {
  max-width: $base-size * 51.25;
  flex: 0 0 auto;
}

.show-conversation {
  flex: 1 1 0;
}
</style>