import { useOnline } from "@vueuse/core";

import { useChannelStatusStore } from "@astrocentro-webapp/commons/modules/chat/stores/channelStatusStore";

export class SetChatChannelStatusService {
  #channelName;
  #channelStatusStore;
  #finishConsultationService;
  #lastHeartbeatFromCounterpart;
  #myPreviousConnectionState;

  /**
   * Constructor
   * @param {string} channelName A valid Pusher's chat channel name like "presence-consultation-1".
   * @param {FinishConsultationService} finishConsultationService The FinishConsultationService from the astrocentro or seller packages
   */
  constructor(channelName, finishConsultationService) {
    this.#channelName = channelName;
    this.#channelStatusStore = useChannelStatusStore();
    this.#finishConsultationService = finishConsultationService;
    this.#lastHeartbeatFromCounterpart = Date.now();
  }

  /**
   * Defines the status of a chat channel.
   * First verifies if user has internet connection,
   * then verifies if a minimum amount of members are connected to the channel.
   *
   * @returns {string} A string representing the status which is either "connected" or "disconnected".
   */
  #getStatus() {
    const isOnline = useOnline();
    const channelMembersCount = this.#channelStatusStore.getChannel(
      this.#channelName
    )?.members?.count;
    const now = Date.now();

    if (!isOnline.value) {
      return "disconnected";
    }

    if (!channelMembersCount) {
      return "disconnected";
    }

    if (channelMembersCount < 2) {
      return "disconnected";
    }

    if (now - this.#lastHeartbeatFromCounterpart > 15000) {
      return "disconnected";
    }

    return "connected";
  }

  /**
   * Update the timestamp of the last heartbeat received from the counterpart
   * 
   * @param {Date} timestamp 
   */
  setLastHeartbeatFromCounterpart(timestamp) {
    this.#lastHeartbeatFromCounterpart = timestamp;
  }

  /**
   * Set the status of a channel by fetching latest channel data from store
   */
  perform() {
    if (!this.#channelName || !this.#finishConsultationService) {
      return;
    }

    this.#channelStatusStore.fetchChannel(this.#channelName);
    let status = this.#getStatus();
    const now = Date.now();
    const isOnline = useOnline();
    const isHeartbeatExpired = (now - this.#lastHeartbeatFromCounterpart > 90000);
    const isCallConnectionTimeoutExpired = (now - this.#lastHeartbeatFromCounterpart > 27500);

    if (isOnline.value && status === "disconnected" && isHeartbeatExpired) {
      this.#finishConsultationService.finishConsultationByDisconnection();
    }

    if (isOnline.value && this.#myPreviousConnectionState == "offline") {
      this.setLastHeartbeatFromCounterpart(now);
      status = this.#getStatus();
    }

    if (isOnline.value && this.#myPreviousConnectionState == "offline" && isCallConnectionTimeoutExpired) {
      window.location.reload();
    }

    this.#channelStatusStore.updateChannel(this.#channelName, { status });
    this.#myPreviousConnectionState = isOnline.value ? 'online' : 'offline';
  }
}
