<script setup>
import { useAudioContext } from '@astrocentro-webapp/sellers/modules/commons/composables/useAudioContext';

const { isAudioContextResumeNeeded, resumeAudioContext } = useAudioContext();

const handleEnableAudio = () => {
  resumeAudioContext();
};
</script>

<template>
  <div class="card shadow-sm bg-light my-3" v-if="isAudioContextResumeNeeded">
    <button class="btn btn-primary" @click="handleEnableAudio">
      <i class="bi bi-volume-up me-2"></i>Habilitar notificações sonoras
    </button>
  </div>
</template>
