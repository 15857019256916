<script setup>
import { computed } from "vue";
import { useOnline } from "@vueuse/core";

import { useChannelStatusStore } from "@astrocentro-webapp/commons/modules/chat/stores/channelStatusStore";
import AstrocentroShuffleIcon from "@astrocentro-webapp/ui/assets/icons/astrocentro-shuffle.svg?component";

const seller = "seller";

const props = defineProps({
  channelName: {
    type: String,
    default: "",
  },
  memberType: {
    type: String,
    default: "",
  },
});

const store = useChannelStatusStore();
const isOnline = useOnline();
const status = computed(() => store.getChannel(props.channelName)?.status);
</script>

<template>
  <div
    v-if="!isOnline || status === 'disconnected'"
    class="fs-9 p-1 text-white bg-danger position-relative d-flex align-items-center justify-content-center"
  >
    <AstrocentroShuffleIcon
      class="me-2"
      :width="12"
      :height="12"
      viewBox="0 0 12 13"
    />
    <span v-if="!isOnline">Você perdeu a conexão, estamos tentando reconectar...</span>
    <span v-else>Seu {{ props.memberType === seller ? `cliente` : `especialista` }} perdeu a conexão, estamos tentando reconectar...</span>
  </div>
</template>
