<script setup>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { VButton } from "@astrocentro-webapp/ui/modules/commons/components/VButton";
import { VInput } from "@astrocentro-webapp/ui-components/modules/commons/components/VInput";
import { VLogo } from "@astrocentro-webapp/ui-components/modules/commons/components/VLogo";
import { LoginService } from "@astrocentro-webapp/sellers/modules/login/services/LoginService";
import { webSocketConnect } from "@astrocentro-webapp/sellers/modules/commons/utils/webSocketConnect";
import { PushNotificationsAdapter } from "@astrocentro-webapp/sellers/modules/commons/adapters/notifications/pusher/PushNotificationsAdapter";
import { DatabaseAdapter } from "@astrocentro-webapp/sellers/modules/commons/adapters/DatabaseAdapter";

let state = reactive({
  submitting: false,
  invalidForm: false,
  invalidFeedback: "",
});

const loginService = new LoginService();
const router = useRouter();

const handleSubmit = async (e) => {
  state.submitting = true;
  state.invalidForm = false;
  state.invalidFeedback = "";

  try {
    const accessToken = await loginService.perform(
        e.target.email.value,
        e.target.password.value
    );

    if (accessToken) {
      const databaseAdapter = new DatabaseAdapter();
      await databaseAdapter.delete();
      await databaseAdapter.open();
      webSocketConnect();
      const user = await loginService.getLoggedInUser();
      const notifications = new PushNotificationsAdapter();
      await notifications.start(user.id);

      router.push("/");
    }
  } catch (error) {
    state.invalidForm = true;
    state.invalidFeedback = error.message;
  }

  state.submitting = false;
};
</script>

<template>
  <div class="container-fluid h-100">
    <div class="row h-100 align-items-center">
      <div class="col-12 col-lg-6">
        <div class="row justify-content-center">
          <div class="col-12 col-sm-7 col-md-7 col-lg-6">
            <VLogo class="mb-6" variant="sm" imgSrc="https://cdn.astrocentro.com.br/webapp/logo-black.png" />
            <h1>Login</h1>
            <p class="lh-1 mb-4 text-gray-600">
              Bem-vindo! Por favor digite seus dados
            </p>
            <form class="" @submit.prevent="handleSubmit">
              <VInput
                  type="text"
                  id="input-login"
                  name="email"
                  label="Email ou nome do usuário"
                  placeholder="Digite seu email"
                  required
                  :isInvalid="state.invalidForm"
                  :invalidFeedback="state.invalidFeedback"
              />
              <VInput
                  type="password"
                  id="input-password"
                  name="password"
                  label="Senha"
                  placeholder="Digite sua senha"
                  required
              />
              <VButton
                  type="submit"
                  class="w-100 btn-primary mt-2"
                  :disabled="state.submitting"
              >
                Entrar
              </VButton>
            </form>
          </div>
        </div>
      </div>
      <div
          class="bg-login d-none d-lg-flex col-lg-6 h-100 bg-gray-300 align-items-end"
      >
        <div class="box-dialog position-relative mb-5 w-100">
          <div class="box-translucent position-absolute h-100 w-100"></div>
          <div
              class="box-dialog-text position-absolute p-3 border border-white w-100"
          >
            <h3 class="text-white">Essa é sua nova experiência Astrocentro</h3>
            <p class="text-white">
              Redesenhamos e aprimoramos sua experiência usando o nosso chat.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";
@import "@astrocentro-webapp/ui/assets/stylesheets/vendor.scss";

.bg-login {
  background: url("https://cdn.astrocentro.com.br/webapp/astrocentro-expert-hands.jpg")
  no-repeat center;
  background-size: cover;
}
@include media-breakpoint-up(lg) {
  .box-dialog {
    height: $base-size * 21.25;
  }
}
@include media-breakpoint-up(xl) {
  .box-dialog {
    height: $base-size * 14.5;
  }
}
.box-translucent {
  backdrop-filter: blur($base-size * 1.875);
  background-color: rgba($white, 0.3);
  top: 0;
  z-index: 1;
}
.box-dialog-text {
  top: 0;
  z-index: 2;
}
</style>
