let serviceWorkerRegistration = null;
let registrationPromise = null;

/**
 * Register the service worker for the application
 * @returns {Promise<ServiceWorkerRegistration>} The service worker registration
 */
export const registerServiceWorker = async () => {
  // Return existing promise if registration is in progress
  if (registrationPromise) {
    return registrationPromise;
  }

  // Return existing registration if already complete
  if (serviceWorkerRegistration) {
    return serviceWorkerRegistration;
  }

  // Start new registration
  if ('serviceWorker' in navigator) {
    registrationPromise = navigator.serviceWorker.register('/audio-service-worker.js')
      .then(registration => {
        serviceWorkerRegistration = registration;
        return registration;
      })
      .catch(error => {
        console.error('Service worker registration failed:', error);
        throw error;
      });
    
    return registrationPromise;
  }
  
  return Promise.reject(new Error('Service Worker API not supported'));
};

/**
 * Get the current service worker registration if available
 * @returns {ServiceWorkerRegistration|null}
 */
export const getServiceWorkerRegistration = () => {
  return serviceWorkerRegistration;
};

/**
 * Check if a service worker is active and controlling the page
 * @returns {boolean}
 */
export const hasActiveServiceWorker = () => {
  return !!navigator.serviceWorker && !!navigator.serviceWorker.controller;
};
