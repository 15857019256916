<script setup>
import { reactive, onMounted, ref, watch } from "vue";
import { UpdateSellerServiceStatusService } from "@astrocentro-webapp/sellers/modules/sellers/services/UpdateSellerServiceStatusService";
import { useServicesStore } from "@astrocentro-webapp/sellers/stores/services";

import { VModal } from "@astrocentro-webapp/ui-components/modules/commons/components/VModal";
import { VButton } from "@astrocentro-webapp/ui/modules/commons/components/VButton";
import { VSwitch } from "@astrocentro-webapp/ui-components/modules/commons/components/VSwitch";
import { LoginService } from "@astrocentro-webapp/sellers/modules/login/services/LoginService";
import { useIdleStore } from "@astrocentro-webapp/sellers/stores/idle";

const idleStore = useIdleStore();
const openModal = ref(false);
const store = useServicesStore();
const updateSellerServiceStatusService = new UpdateSellerServiceStatusService(
  store
);
const loginService = new LoginService();
const user = await loginService.getLoggedInUser();

const props = defineProps({
  open: {
    type: Boolean,
  },
});

const availability = reactive({
  "phone-consultation": false,
  "chat-consultation": false,
});

const unavailableChecked = ref(true);

const handleAvailability = async () => {
  idleStore.idle = false;
  openModal.value = false;
  await updateSellerServiceStatusService.perform(availability);
};

const handleUnavailable = () => {
  unavailableChecked.value = !unavailableChecked.value;
  availability["phone-consultation"] = !unavailableChecked.value;
  availability["chat-consultation"] = !unavailableChecked.value;
};

watch(availability, () => {
  unavailableChecked.value = !(
    availability["phone-consultation"] || availability["chat-consultation"]
  );
});

onMounted(() => {
  const hasLoggedin = sessionStorage.getItem("hasLoggedin");
  if (!hasLoggedin) {
    openModal.value = true;
    sessionStorage.setItem("hasLoggedin", true);
  }
});
</script>

<template>
  <VModal
    :open="openModal || idleStore.idle"
    id="avail-modal"
    @modal-closed="openModal = false"
    class="text-center"
    :logo="true"
    logoSrc="https://cdn.astrocentro.com.br/webapp/logo-black.png"
  >
    <div class="p-md-2 pt-md-0 mx-md-4">
      <h1 class="mb-3">
        {{
          idleStore.idle
            ? "Ainda está disponível?"
            : "Qual sua Disponibilidade?"
        }}
      </h1>
      <p class="text-gray-500 mb-5">
        Selecione as opções abaixo de acordo com a sua disponibilidade de
        serviços. Seu status só modificará por sua alteração manual.
      </p>
      <VSwitch
        v-if="user.seller?.tph_wp_account_seller?.attends_in_new_call"
        id="chose-option-availability-phone"
        label="Me mantenha online apenas por Telefone"
        value="opt-1"
        :checked="availability['phone-consultation']"
        name="chose-option-availability"
        variant="md"
        @click="
          availability['phone-consultation'] =
            !availability['phone-consultation']
        "
      />
      <hr class="m-0 bg-gray-300" />
      <VSwitch
        id="chose-option-availability-chat"
        label="Me mantenha online apenas por Chat"
        value="opt-1"
        :checked="availability['chat-consultation']"
        name="chose-option-availability"
        variant="md"
        class="py-3"
        @click="
          availability['chat-consultation'] = !availability['chat-consultation']
        "
      />
      <hr class="m-0 bg-gray-300" />
      <VSwitch
        id="chose-option-unavailable"
        label="Me mantenha Offline"
        value="opt-2"
        :checked="unavailableChecked"
        name="chose-option-availability"
        variant="md"
        class="py-3"
        @click="handleUnavailable"
      />
      <div class="d-grid mt-5">
        <VButton class="btn-lg btn-primary" @click="handleAvailability"
          >Continue</VButton
        >
      </div>
    </div>
  </VModal>
</template>
