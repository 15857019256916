<script setup>
import { ref } from "vue";
import { useRouter, RouterLink } from "vue-router";
import { computedAsync, computedEager } from "@vueuse/core";
import { storeToRefs } from "pinia";

import { SellerAvatar } from "@astrocentro-webapp/ui-components/modules/sellers/components/SellerAvatar";
import { VLogo } from "@astrocentro-webapp/ui-components/modules/commons/components/VLogo";
import { VModal } from "@astrocentro-webapp/ui-components/modules/commons/components/VModal";
import { AvailabilitySwitch } from "@astrocentro-webapp/sellers/modules/commons/components/AvailabilitySwitch";
import { VTooltip } from "@astrocentro-webapp/ui-components/modules/commons/components/VTooltip";
import { HasConsultationQueueModal } from "@astrocentro-webapp/sellers/modules/call/components/HasConsultationQueueModal";
import Logout from "@astrocentro-webapp/ui-components/assets/icons/log-out.svg?component";
import TextSquare from "@astrocentro-webapp/ui-components/assets/icons/text-square.svg?component";
import { useBreakpoints } from "@astrocentro-webapp/ui-components/modules/commons/composables/browser/useBreakpoints";
import { useServicesStore } from "@astrocentro-webapp/sellers/stores/services";
import { useConsultationsQueueStore } from "@astrocentro-webapp/sellers/stores/consultationsQueue";
import { LoginService } from "@astrocentro-webapp/sellers/modules/login/services/LoginService";

/**
 * TODO: Experiment
 * @see {@link https://canoadigital.atlassian.net/browse/AST-998}
 */
const open = ref(false);
const isHasConsultationQueueModalOpen = ref(false);
const router = useRouter();
const store = useServicesStore();
const consultationsQueueStore = useConsultationsQueueStore();
const { consultationsQueue } = storeToRefs(consultationsQueueStore);
const breakpoints = useBreakpoints();
const isSmallerThanMd = computedEager(() => breakpoints.smaller("lg").value);
const status = computedAsync(async () => {
  const services = await store.services;
  const status = (services["chat-consultation"].connected || services["phone-consultation"].connected) ? "available"
      : "unavailable";

  return status;
}, "unavailable");

const loginService = new LoginService();
const user = await loginService.getLoggedInUser();

const modalToggle = (value) => {
  open.value = value;
};

const handleLogoutClick = async () => {
  if (consultationsQueue.value.length) {
    isHasConsultationQueueModalOpen.value = true;

    return;
  }

  await router.push(`/logout`);
};
</script>

<template>
  <header class="border-bottom border-gray-100">
    <div class="container-fluid">
      <div class="row">
        <div class="col-4 col-lg-3 d-flex align-items-center">
          <RouterLink to="/">
            <VLogo variant="sm" imgSrc="https://cdn.astrocentro.com.br/webapp/logo-black.png" />
          </RouterLink>
        </div>
        <div
            class="col-8 col-lg-9 d-flex justify-content-end align-items-center"
        >
          <!--
            TODO: Experiment
            @see {@link https://canoadigital.atlassian.net/browse/AST-998}
          -->
          <span
              class="fs-9 me-3 text-primary"
              role="button"
              @click="modalToggle(true)"
              v-if="!isSmallerThanMd"
          >
            Veja o histórico de movimento do site
          </span>
          <div class="justify-content-end align-items-center d-flex">
            <div
                class="px-4 fs-8"
                v-if="!isSmallerThanMd"
            >
              <VTooltip
                  placement="bottom"
                  title="Você pode mudar seu status clicando em uma opção"
              >
                <AvailabilitySwitch />
              </VTooltip>
            </div>
            <div class="ps-5 pe-2 py-2 border-start border-gray-100">
              <SellerAvatar
                  :status="status"
                  :src="user.seller.profile_picture"
                  size="xs"
                  class="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  id="dropdownAvatar"
                  role="button"
              />
              <ul
                  class="dropdown-menu shadow-sm"
                  aria-labelledby="dropdownAvatar"
              >
                <li class="dropdown-item d-flex">
                  <SellerAvatar
                      :status="status"
                      :src="user.seller.profile_picture"
                      size="xs"
                  />
                  <div class="ps-2">
                    <p class="fs-8 fw-bold text-gray-700 m-0">
                      {{ user.seller.name }}
                    </p>
                    <p class="fs-8 text-gray-600 m-0">
                      {{ user.seller.email }}
                    </p>
                  </div>
                </li>
                <!--
                  TODO: Experiment
                  @see {@link https://canoadigital.atlassian.net/browse/AST-998}
                -->
                <li @click="modalToggle(true)" v-if="isSmallerThanMd">
                  <hr class="dropdown-divider" />
                  <span class="dropdown-item fs-8 text-gray-700">
                        Veja o histórico de movimento do site
                      </span>
                </li>
                <li v-if="isSmallerThanMd">
                  <hr class="dropdown-divider" />
                  <VTooltip
                      class="dropdown-item fs-8 text-gray-700 ps-0 py-0"
                      placement="bottom"
                      title="Você pode mudar seu status clicando aqui"
                  >
                    <AvailabilitySwitch />
                  </VTooltip>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                  <RouterLink
                      to="/consultations/history"
                      class="dropdown-item fs-8 text-gray-700"
                  ><TextSquare width="16" height="16" class="me-2" />Histórico de consultas</RouterLink
                  >
                </li>
                <li>
                  <hr class="dropdown-divider" />
                  <a
                      class="dropdown-item fs-8 text-gray-700"
                      href="#"
                      @click="handleLogoutClick"
                  ><Logout width="16" height="16" class="me-2" />Sair do
                    Chat</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--
      TODO: Experiment
      @see {@link https://canoadigital.atlassian.net/browse/AST-998}
    -->
    <VModal
        :open="open"
        id="visitors-in-site-modal"
        class="text-center"
        :logo="true"
        @modal-closed="modalToggle(false)"
    >
      <iframe
          width="100%"
          height="450"
          src="https://lookerstudio.google.com/embed/reporting/f6028e43-e73f-48b0-b5e3-d78015de8d37/page/J6tQD"
          frameborder="0"
          style="border: 0"
          allowfullscreen
      ></iframe>
    </VModal>
    <HasConsultationQueueModal
        :open="isHasConsultationQueueModalOpen"
        @modal-closed="isHasConsultationQueueModalOpen = false"
    />
  </header>
</template>

<style lang="scss" scoped>
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";
.dropdown-menu {
  min-width: $base-size * 32.5;
}
.dropdown-item {
  padding: calc($base-size * 1.5) calc($base-size * 2);
}
.dropdown-divider {
  margin: 0;
}
</style>
