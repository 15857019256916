import { ChannelEvents } from "@astrocentro-webapp/commons/modules/commons/events/ChannelEvents";
import { storeMessageInExternalDatabaseListener } from "@astrocentro-webapp/commons/modules/chat/listeners/storeMessageInExternalDatabaseListener";
import { SetChatChannelStatusService } from "@astrocentro-webapp/commons/modules/chat/services/SetChatChannelStatusService";
import { dispatchCustomEvent } from "@astrocentro-webapp/commons/modules/chat/listeners/dispatchCustomEvent";
import { updateConsultationsStoreListener } from "@astrocentro-webapp/sellers/modules/commons/listeners/updateConsultationsStoreListener";
import { playNewConsultationAudioListener } from "@astrocentro-webapp/sellers/modules/commons/listeners/playNewConsultationAudioListener";
import { storeConversationInLocalDatabaseListener } from "@astrocentro-webapp/sellers/modules/commons/listeners/storeConversationInLocalDatabaseListener";
import { storeMessageInLocalDatabaseListener } from "@astrocentro-webapp/sellers/modules/chat/listeners/storeMessageInLocalDatabaseListener";
import { addNewMessageNotificationListener } from "@astrocentro-webapp/sellers/modules/chat/listeners/addNewMessageNotificationListener";
import { updateMessageWasReadListener } from "@astrocentro-webapp/sellers/modules/chat/listeners/updateMessageWasReadListener";
import { inactiveConsultationListener } from "@astrocentro-webapp/sellers/modules/chat/listeners/inactiveConsultationListener";
import { handleClientCallDeviceRegisteredListener } from "@astrocentro-webapp/sellers/modules/call/listeners/handleClientCallDeviceRegisteredListener";
import { FinishConsultationService } from "@astrocentro-webapp/sellers/modules/commons/services/FinishConsultationService";

export class ConsultationChannelEvents extends ChannelEvents {
  #setChatChannelStatusService;
  static #activeIntervals = new Map();
  static #activeChannels = new Map();
  #consultationId;

  constructor(consultationId) {
    super(`presence-consultation-${consultationId}`);

    // Return existing instance if one exists
    if (ConsultationChannelEvents.#activeChannels.has(consultationId)) {
      return ConsultationChannelEvents.#activeChannels.get(consultationId);
    }

    this.#consultationId = consultationId;
    this.#setChatChannelStatusService = new SetChatChannelStatusService(
      this.channelName,
      new FinishConsultationService(consultationId)
    );

    ConsultationChannelEvents.#activeChannels.set(consultationId, this);
  }

  bindListeners() {
    const channel = window.webSocketAdapter.subscribe(this.channelName);
    channel.unbind();

    // Clear existing interval if it exists for this consultation
    if (ConsultationChannelEvents.#activeIntervals.has(this.#consultationId)) {
      clearInterval(ConsultationChannelEvents.#activeIntervals.get(this.#consultationId));
      ConsultationChannelEvents.#activeIntervals.delete(this.#consultationId);
    }

    // Store the interval ID in the static Map
    const interval = setInterval(() => {
      console.log('sending seller heartbeat');
      channel.trigger("client-heartbeat", {});
      this.#setChatChannelStatusService.perform();
    }, 5000);

    ConsultationChannelEvents.#activeIntervals.set(this.#consultationId, interval);

    channel
      .bind("client-chat-message-received", (event) => {
        dispatchCustomEvent("new-message-received");
        dispatchCustomEvent("client-is-typing");
        storeMessageInLocalDatabaseListener(event);
        this.trigger("client-chat-message-read", event);
        addNewMessageNotificationListener(event.consultationId);
      })
      .bind("client-chat-message-read", (message) => {
        updateMessageWasReadListener(message);
        storeMessageInExternalDatabaseListener(message);
      })
      .bind("client-chat-auto-message-read", (event) => {
        storeMessageInLocalDatabaseListener(event);
      })
      .bind("client-chat-consultation-requested", (event) => {
        storeConversationInLocalDatabaseListener(event);
        updateConsultationsStoreListener(
          event.consultation,
          event.customer,
          event.seller
        );
        playNewConsultationAudioListener();
      })
      .bind("client-is-typing", () => {
        dispatchCustomEvent("client-is-typing");
      })
      .bind("client-is-not-typing", () => {
        dispatchCustomEvent("client-is-not-typing");
      })
      .bind("client-call-consultation-requested", (event) => {
        updateConsultationsStoreListener(
          event.consultation,
          event.consultation.customer,
          event.consultation.seller
        );

        if (event.consultation.last_status === "in-progress") {
          return;
        }

        playNewConsultationAudioListener();
      })
      .bind("client-call-device-registered", async (event) => {
        handleClientCallDeviceRegisteredListener(event);
      })
      .bind("client-heartbeat", () => {
        console.log('receiving customer heartbeat');
        this.#setChatChannelStatusService.setLastHeartbeatFromCounterpart(Date.now());
      })
      .bind("chat-consultation-finished", (event) => {
        const existingChatInterval = ConsultationChannelEvents.#activeIntervals.get(this.#consultationId);
        if (existingChatInterval) {
          clearInterval(existingChatInterval);
          ConsultationChannelEvents.#activeIntervals.delete(this.#consultationId);
        }

        inactiveConsultationListener(event);
        channel.unbind();
      })
      .bind("call-consultation-finished", (event) => {
        const existingCallInterval = ConsultationChannelEvents.#activeIntervals.get(this.#consultationId);
        if (existingCallInterval) {
          clearInterval(existingCallInterval);
          ConsultationChannelEvents.#activeIntervals.delete(this.#consultationId);
        }

        inactiveConsultationListener(event);
        channel.unbind();
      })
      .bind("pusher:subscription_succeeded", () => {
        this.#setChatChannelStatusService.perform();
      });
  }
}
