import { hasActiveServiceWorker } from '@astrocentro-webapp/sellers/services/serviceWorkerRegistration';

export class PlayStopNewConsultationAudio {
  #audio;

  constructor() {
    if (PlayStopNewConsultationAudio._instance) {
      return PlayStopNewConsultationAudio._instance;
    }
    
    this.#audio = new Audio(
      "https://cdn.astrocentro.com.br/webapp/clinking-teaspoon.mp3"
    );
    
    PlayStopNewConsultationAudio._instance = this;
  }

  play() {
    if (this.#audio.paused) {
      this.#audio.loop = true;
      this.#audio.load();
      this.#audio.play().catch((error) => {
        console.log("Audio playback error: ", error);

        this.#notifyViaServiceWorker('consultation-request');
      });
    }
  }

  stop() {
    if (!this.#audio.paused) {
      try {
        this.#audio.loop = false;
        this.#audio.load();
        this.#audio.pause();
      } catch (error) {
        console.log("error", error);
      }
    }
    
    this.#notifyViaServiceWorker('close-notification');
  }

  #notifyViaServiceWorker(type) {
    if (hasActiveServiceWorker()) {
      navigator.serviceWorker.controller.postMessage({ type });
    }
  }
}
