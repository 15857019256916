<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { VTooltip } from "@astrocentro-webapp/ui-components/modules/commons/components/VTooltip";
import AlarmIcon from "@astrocentro-webapp/ui-components/assets/icons/alarm.svg?component";

const props = defineProps({
  consultation: {
    type: Object,
    required: true
  }
});

const fiveMinutesInSeconds = 5 * 60;
const timeLeft = ref(null);
const intervalId = ref(null);

const shouldShowWarning = computed(() => {
  return (
    props.consultation &&
    props.consultation.last_status === 'in-progress' &&
    timeLeft.value !== null &&
    timeLeft.value <= fiveMinutesInSeconds &&
    timeLeft.value > 0
  );
});

const calculateTimeLeft = () => {
  if (!props.consultation || !props.consultation.start_date || !props.consultation.max_duration) {
    timeLeft.value = null;
    return;
  }
  
  const startTimestamp = new Date(props.consultation.start_date).getTime();
  const currentTimestamp = new Date().getTime();
  const elapsedSeconds = Math.floor((currentTimestamp - startTimestamp) / 1000);
  const remainingSeconds = props.consultation.max_duration - elapsedSeconds;
  
  timeLeft.value = remainingSeconds;
};

onMounted(() => {
  calculateTimeLeft();
  intervalId.value = setInterval(calculateTimeLeft, 30000);
});

onUnmounted(() => {
  if (intervalId.value) {
    clearInterval(intervalId.value);
  }
});
</script>

<template>
  <VTooltip
    v-if="shouldShowWarning"
    placement="bottom"
    title="Essa consulta finaliza em menos de 5 minutos"
    style="display: inline;"
  >
    <span class="badge py-2 px-3 fs-8 rounded text-white bg-warning-300 m-1">
      <AlarmIcon />
    </span>
  </VTooltip>
</template>
