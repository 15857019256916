import { Device } from "@twilio/voice-sdk";
import { useIntervalFn } from "@vueuse/core";

import { HttpClient } from "@astrocentro-webapp/commons/modules/commons/clients/HttpClient";

/**
 * @implements Singleton
 */
export class CallClient extends Device {
  static instance = null;

  /**
   * Creates a new instance of the Device class with the provided token and options.
   *
   * This constructor is used to create a new Device instance with the specified token and optional options.
   * It extends the base class and initializes the Device instance accordingly.
   *
   * @param token - The authentication token associated with the device.
   * @param [options] - An optional object containing additional configuration options for the device.
   */
  constructor(token, options = {}) {
    super(token, options);
  }

  /**
   * Retrieve an access token for making authenticated calls.
   *
   * This method sends an HTTP POST request to obtain an access token for making authenticated calls.
   * It accepts optional HTTP options for customization.
   *
   * @param [data] - An optional object containing HTTP options for the request.
   * @returns A promise that resolves with the HTTP response containing the access token if successful, or void if an error occurs.
   *
   * @throws If the access token request fails or if the response status code is not 201 (Created).
   */
  static async getToken(data = {}) {
    try {
      const httpClient = HttpClient.create();
      const token = await httpClient.post(
        `${import.meta.env.VITE_ASTROCENTRO_API_URL}/v1/call-access-tokens`,
        data
      );

      if (!token || token.status !== 201) {
        return;
      }

      return token;
    } catch (error) {
      console.error("CallClient.getToken", error);
    }
  }

  /**
   * Handle the scenario when the access token is about to expire.
   *
   * This private static method is used to handle the situation when the access token is about to expire.
   * It retrieves a new access token by making an HTTP request and updates the device with the new token.
   *
   * @param device - The device for which the token is about to expire.
   * @param [options] - An optional object containing HTTP options for the token retrieval request.
   * @returns A promise that resolves once the device has been updated with the new access token.
   *
   * @throws If there is an issue with retrieving the new access token or updating the device with it.
   */
  static async handleTokenWillExpire(device, options = {}) {
    try {
      const token = await CallClient.getToken(options.data);

      if (!token) {
        return;
      }

      device.updateToken(token.data.token);
    } catch (error) {
      console.error("CallClient.handleTokenWillExpire", error);
    }
  }

  /**
   * Create an instance of the CallClient for making and receiving calls.
   *
   * This private static method creates an instance of the CallClient class, which is used for making and receiving calls.
   * It handles token retrieval, configuration options, event listeners, and registration of the client for call functionality.
   *
   * @param [options] - An optional object containing configuration options for the CallClient.
   * @returns A promise that resolves with the CallClient instance if created successfully; otherwise, `null`.
   *
   * @throws If there is an issue with token retrieval, client creation, or registration.
   *
   * @see {@link https://www.twilio.com/docs/voice/sdks/javascript/best-practices#debugging}
   */
  static async makeInstance(options = {}) {
    try {
      const token = await CallClient.getToken(options.data);

      if (!token) {
        return null;
      }

      const logLevel = import.meta.env.DEV ? 1 : 3;
      const codecPreferences = ["opus", "pcmu"];
      // const closeProtection =
      //   "Uma consulta está em andamento. Sair ou recarregar a página encerrará a chamada.";

      const callClient = new CallClient(token.data.token, {
        ...options,
        logLevel,
        codecPreferences,
        // closeProtection,
        allowIncomingWhileBusy: true,
        maxCallSignalingTimeoutMs: 30000,
      });

      callClient.on("registered", async () => {
        console.info("Client is ready to make and receive calls");
      });

      if ("listeners" in options && "handleIncoming" in options.listeners) {
        callClient.on("incoming", async (call) => {
          await options.listeners.handleIncoming(call);
        });
      }

      callClient.on("tokenWillExpire", async () => {
        CallClient.handleTokenWillExpire(callClient, options);
      });

      callClient.on("error", async (error) => {
        console.error("CallClient.makeInstance", error.message);
      });

      await callClient.register();

      return callClient;
    } catch (error) {
      console.error("CallClient.makeInstance");
    }

    return null;
  }

  /**
   * Get or create a singleton instance of the CallClient.
   *
   * This static method allows you to obtain a singleton instance of the CallClient class, which is responsible for handling calls.
   * It ensures that there is only one instance of CallClient for the entire application.
   * If an instance does not exist, it creates one using the provided options or defaults.
   *
   * @param [options] - An optional object containing configuration options for the CallClient.
   * @returns A promise that resolves with the existing or newly created CallClient instance; otherwise, `null` if an error occurs.
   *
   * @throws If there is an issue with creating the instance.
   */
  static async getInstance(options = {}) {
    try {
      if (!CallClient.instance) {
        CallClient.instance = await CallClient.makeInstance(options);
      }
    } catch (error) {
      console.error("CallClient.getInstance", error);
    }

    return CallClient.instance;
  }

  /**
   * Get the active call instance.
   *
   * This method retrieves the active call instance, if available.
   * It checks if the CallClient is initialized and, if so, returns the currently active call, or `null` if no active call exists.
   * @returns The active Call instance, or `null` if no active call is present.
   */
  getActiveCall() {
    if (!CallClient.instance) {
      return null;
    }

    const call = CallClient.instance._activeCall;

    return call;
  }

  /**
   * Disconnect all active calls.
   *
   * This method initiates the disconnection of all active calls.
   * It checks if the CallClient is initialized, and if so, disconnects all active calls.
   * Once the disconnection process is completed, it resolves with a boolean value indicating the success of the operation.
   * @returns A promise that resolves to `true` when all active calls are successfully disconnected; otherwise, `false`.
   */
  async disconnectAll() {
    if (!CallClient.instance) {
      return false;
    }

    super.disconnectAll();
    CallClient.instance._activeCall = null;

    return await new Promise((resolve) => {
      const { pause } = useIntervalFn(() => {
        const hasActiveCall = this.getActiveCall();

        if (!hasActiveCall) {
          pause();
          resolve(true);
        }
      }, 5000);
    });
  }
}
