import { ConsultationRepository } from "@astrocentro-webapp/sellers/modules/commons/repositories/ConsultationRepository";

export class FinishConsultationService {
  #consultationRepository;
  #consultationId;
  
  constructor(consultationId) {
    this.#consultationRepository = new ConsultationRepository();
    this.#consultationId = consultationId;
  }
  
  async finishConsultationByDisconnection() {
    return await this.#consultationRepository.update(this.#consultationId, {
      last_status: "finished",
      last_status_reason: "customer-disconnected",
    });
  }
}