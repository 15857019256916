import { ref, onMounted } from 'vue';

export function useAudioContext() {
  const audioContext = ref(null);
  const isAudioContextResumeNeeded = ref(false);

  onMounted(() => {
    if (audioContext.value === null) {
      try {
        const AudioContextClass = window.AudioContext || window.webkitAudioContext;
        audioContext.value = new AudioContextClass();
        
        if (audioContext.value.state === 'suspended') {
          isAudioContextResumeNeeded.value = true;
        }

        document.addEventListener('visibilitychange', () => {
          audioContext.value = new AudioContextClass();

          if (audioContext.value.state === 'suspended') {
            isAudioContextResumeNeeded.value = true;
          } else {
            isAudioContextResumeNeeded.value = false;
          }
        });
      } catch (e) {
        console.error('Web Audio API is not supported in this browser', e);
      }
    }
  });

  const resumeAudioContext = async () => {
    if (audioContext.value && audioContext.value.state === 'suspended') {
      await audioContext.value.resume();
    }

    isAudioContextResumeNeeded.value = false;
  };

  const getAudioContext = () => audioContext;

  return {
    isAudioContextResumeNeeded,
    resumeAudioContext,
    getAudioContext
  };
}
