<script setup>
import { DateHelper } from "@astrocentro-webapp/commons/modules/commons/helpers/DateHelper";

const dateHelper = new DateHelper();

const props = defineProps({
  consultationEndDate: {
    type: String,
  },
  reason: {
    type: String,
    default: "",
  },
});
</script>

<template>
  <div>
    <h3 class="mb-1">
      Esse atendimento foi finalizado às
      {{ dateHelper.format(props.consultationEndDate, "HH:mm") }}
    </h3>
    <p v-if="props.reason === 'customer-disconnected'" class="text-gray-700 mb-0">
      Seu cliente perdeu a conexão, vamos te avisar se ele voltar a procurar sua especialidade
    </p>
    <p v-else class="text-gray-700 mb-0">
      Vamos te avisar quando seu cliente voltar a procurar sua especialidade
    </p>
  </div>
</template>
