import { useConsultationsStore } from "@astrocentro-webapp/sellers/stores/consultations";
import { PlayStopNewConsultationAudio } from "@astrocentro-webapp/sellers/modules/commons/audios/PlayStopNewConsultationAudio.js";
import { useCallStore } from "@astrocentro-webapp/sellers/stores/call";
import { storeToRefs } from "pinia";

export const inactiveConsultationListener = async (event) => {
  const consultationsStore = useConsultationsStore();
  const newConsultationAudio = new PlayStopNewConsultationAudio();
  const store = useCallStore();
  const { call } = storeToRefs(store);

  if (call.value) {
    call.value.disconnect();
    call.value = null;
  }

  consultationsStore.$patch(async (state) => {
    let activeCalls = await state.consultations["Chamadas Ativas"];
    let inactiveCalls = await state.consultations["Chamadas Inativas"];
    newConsultationAudio.stop();

    activeCalls = activeCalls.filter((item) => {
      if (item.id == event.consultation.id) {
        inactiveCalls.push({ ...item, ...event.consultation });
      }
      return item.id != event.consultation.id;
    });

    state.consultations = {
      "Chamadas Ativas": activeCalls,
      "Chamadas Inativas": inactiveCalls,
    };
  });
};
