import { createApp } from "vue";
import { createPinia } from "pinia";
import { webSocketConnect } from "@astrocentro-webapp/sellers/modules/commons/utils/webSocketConnect";
import * as Sentry from "@sentry/vue";
import { registerServiceWorker } from "@astrocentro-webapp/sellers/services/serviceWorkerRegistration";

import App from "./App.vue";
import router from "./router";

// Register service worker
registerServiceWorker()
  .then(() => {
    console.log('Service worker registered successfully');
  })
  .catch((error) => {
    console.warn('Service worker registration failed, notifications may not work', error);
  });

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://5a8ee16e397748e7bbe5f7e74283bcf7@o457859.ingest.sentry.io/4505229075087360",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 0.1, 
  replaysSessionSampleRate: 0, 
  replaysOnErrorSampleRate: 1.0, 
  enabled: import.meta.env.VITE_ENVIRONMENT == "production",
});

app.use(createPinia());
webSocketConnect();
app.use(router);

app.mount("#app");
