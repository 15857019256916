<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { TheHeader } from "@astrocentro-webapp/sellers/modules/commons/components/TheHeader";
import { ListConversations } from "@astrocentro-webapp/sellers/modules/chat/components/ListConversations";
import { ModalChatSucceded } from "@astrocentro-webapp/sellers/modules/chat/components/ModalChatSucceded";
import { WaitingNotice } from "@astrocentro-webapp/sellers/modules/chat/components/WaitingNotice";
import { AudioInteractionPrompt } from "@astrocentro-webapp/sellers/modules/commons/components/AudioInteractionPrompt";

const isModalChatSuccededOpen = ref(false);
const finishedConsultationId = ref(null);
const route = useRoute();
const router = useRouter();

const handleChatSuccededModalClose = () => {
  isModalChatSuccededOpen.value = false;
  router.replace({ query: null });
};

onMounted(() => {
  if (route.query.displayConsultationSummary && route.query.consultationId) {
    finishedConsultationId.value = route.query.consultationId;
    isModalChatSuccededOpen.value = true;
  }
});
</script>

<template>
  <main class="d-flex flex-column">
    <TheHeader />
    <div class="container-fluid h-100">
      <div class="row h-100">
        <div class="col-lg-4 px-0 h-100 border-end border-gray-100 list-conversation">
          <ListConversations />
        </div>
        <div class="col-lg-8 px-0 d-none d-lg-block h-100">
          <div class="px-3 h-100">
            <div class="row justify-content-center align-items-center h-100">
              <div class="col-8">
                <WaitingNotice />
                <AudioInteractionPrompt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <ModalChatSucceded
    :open="isModalChatSuccededOpen"
    :consultationId="finishedConsultationId"
    @modal-closed="handleChatSuccededModalClose"
  />
</template>
<style lang="scss" scoped>
@import "@astrocentro-webapp/ui-components/assets/stylesheets/variables.scss";
.list-conversation {
  max-width: $base-size * 51.25;
}
</style>